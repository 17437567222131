import React, { FunctionComponent, useState } from 'react';
import Router from 'next/router';
import { Box, Text, Flex, Heading, LoadingIcon, ButtonLink } from 'mdlkit';
import styled, { useTheme } from 'styled-components';
import { useIntl, FormattedMessage } from 'react-intl';

import injectToString, { HTML_ERRORS } from '../utils/login/injectToString';
import { AppCard, CallCustomerService } from '../components/shared';
import {
  UsernameOrEmailForm,
  PasswordForm,
  ChooseAccount,
  NoPrimaryAccountFound,
} from '../components/login';
import AutoLoginContainer from './login/AutoLoginContainer';
import AuthCodeVerificationWithoutSessionContainer from './login/AuthCodeVerificationWithoutSessionContainer';
import { Auth } from '../interfaces/auth';
import {
  LOCKED_USER,
  MISSING_PROFILE,
  ERROR_403_BLOCKED,
  TOO_MANY_REQUESTS,
} from '../constants/errors';
import loginAsync from '../api/login/loginAsync';
import accountExistenceAsync from '../api/login/accountExistenceAsync';
import createAuthCodeAsync from '../api/login/createAuthCodeAsync';
import multipleAccountsAsync from '../api/login/multipleAccountsAsync';
import toast from '../utils/toast';
import { ValidateUser, AuthCodeResponse } from '../interfaces/login';
import {
  EMAIL,
  PASSWORD_RECOVERY_URL,
  BLOCKED_ACCOUNT_URL,
} from '../constants/login';
import { useCustomizable } from '../components/app/CustomizableProvider';
import { parseCustomParams } from '../utils/customParams';

interface Props {
  user?: ValidateUser;
  displayCreateAccount?: boolean;
}

const LastSignedIn = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  padding: 30px 10px 0px 10px;
  img {
    max-width: 80px;
  }
`;

const LoginPage: FunctionComponent<Props> = ({
  user: u,
  displayCreateAccount = true,
}: Props) => {
  const [auth, setAuth] = useState<Auth | null>(null);
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const [step, setStep] = useState<number>(u ? 4 : 1);
  const [authCodeRes, setAuthCodeRes] = useState<AuthCodeResponse | null>(null);
  const [usernameOrEmail, setUsernameOrEmail] = useState<string>('');
  const [userList, setUserList] = useState<ValidateUser[] | undefined>(
    undefined
  );
  const [user, setUser] = useState<ValidateUser | undefined>(u);
  const [hiddenPassword, setHiddenPassword] = useState<string>('');
  const { formatMessage: f } = useIntl();
  const { params } = useCustomizable();

  const sendAuthCode = async (token) => {
    const { authCode, error } = await createAuthCodeAsync({
      token,
      connection: EMAIL,
    });

    if (authCode) {
      setAuthCodeRes(authCode);
      setStep(2);
      window.createPageLoadEvent('/sign-in#accountcode');
    } else {
      let errorCause = '';
      if (error?.code === TOO_MANY_REQUESTS) {
        errorCause = f({ id: 'login.accountRecovery.failed.tooManyRequests' });
        toast.error(f({ id: 'login.accountRecovery.failed.tooManyRequests' }));
      } else {
        errorCause = f({ id: 'error.unexpectedError' });
        toast.error(
          <CallCustomerService message={f({ id: 'error.unexpectedError' })} />
        );
      }

      window.createErrorEvent(
        {
          controlName: 'username-attempt',
          controlText: 'Continue',
        },
        {
          errorLocation: 'User-Login-Submit',
          errorType: 'Internal_API',
          errorCode: error?.code ?? '',
          errorCause,
        }
      );
    }
  };

  const handleSubmitUsernameOrEmail = async (values: {
    usernameOrEmail: string;
    hiddenPassword: string;
  }) => {
    const usernameOrEmailTrimmed = values.usernameOrEmail.trim();
    setUsernameOrEmail(usernameOrEmailTrimmed);
    setHiddenPassword(values.hiddenPassword);
    const { users, error } = await accountExistenceAsync({
      usernameOrEmail: usernameOrEmailTrimmed,
    });

    if (users) {
      window.createPageActionEvent({
        controlName: 'username-success',
        controlText: 'Continue',
      });
      if (users.length > 1) {
        await sendAuthCode(users[0].token);
      } else {
        setStep(4);
        window.createPageLoadEvent('/sign-in#password');
      }

      setUser(users[0]);
    } else if (error) {
      window.createErrorEvent(
        {
          controlName: 'username-attempt',
          controlText: 'Continue',
        },
        {
          errorLocation: 'User-Login-Submit',
          errorType: 'Internal_API',
          errorCode: error.code ?? '',
          errorCause: error.message ? error.message.join(', ') : '',
        }
      );

      const injectedMessage = injectToString(
        error?.message?.join(',') ?? '',
        HTML_ERRORS.recoverMyAccount
      );

      toast.errorHtml(injectedMessage);
    }
  };

  const handleAuthCodeSuccess = async () => {
    if (user) {
      const { users, error } = await multipleAccountsAsync({
        userIdentifier: usernameOrEmail,
      });
      if (users) {
        setStep(3);
        setUserList(users);
        window.createPageLoadEvent('/sign-in#multipleaccounts');
        return;
      }

      window.createErrorEvent(
        {
          controlName: 'resend-code-account-link-attempt',
          controlText: 'Verify',
        },
        {
          errorLocation: 'Login-Submit',
          errorType: 'Internal_API',
          errorCode: error?.code ?? '',
          errorCause: error?.message.join(',') ?? '',
        }
      );
      toast.errorHtml(error?.message.join(','));
    }
  };

  const handleSubmitPassword = async (values: { password: string }) => {
    if (user) {
      const loginResp = await loginAsync({
        email: user.username,
        password: values.password,
      });
      if ('error' in loginResp) {
        const { error } = loginResp;
        const errorData = {
          errorLocation: 'Login-Submit',
          errorType: 'Internal_API',
          errorCode: error.code ?? '',
          errorCause: '',
        };

        toast.dismiss();
        if (error.code === LOCKED_USER) {
          toast.dismiss();
          Router.push(
            parseCustomParams(
              `${BLOCKED_ACCOUNT_URL}?token=${user?.token}`,
              params
            )
          );
        } else if (error.code === MISSING_PROFILE) {
          toast.dismiss();
          window.location.href = parseCustomParams(error.redirectUrl, params);
        } else if (error.code === ERROR_403_BLOCKED) {
          Router.push(
            parseCustomParams(
              `${error.redirectUrl}?errorID=${error.message}`,
              params
            )
          );
        } else if (error.code === TOO_MANY_REQUESTS) {
          const errorMessage = f({
            id: 'login.accountRecovery.failed.tooManyRequests',
          });
          errorData.errorCause = errorMessage;
          toast.error(errorMessage);
        } else {
          toast.error(error.message.join(', '));
        }

        errorData.errorCause =
          errorData.errorCause === ''
            ? error.message.join(', ')
            : errorData.errorCause;
        window.createErrorEvent(
          {
            controlName: 'sign-in-attempt',
            controlText: 'Sign in',
          },
          errorData
        );
      } else {
        const { userId, sso, jwt, redirectUrl: redirect } = loginResp;

        window.createPageActionEvent(
          {
            controlName: 'sign-in-success',
            controlText: 'Sign in',
          },
          { userID: user.id.toString(), loggedInStatus: 'NOT_LOGGED_IN' }
        );

        setStep(5);
        setAuth({
          userId,
          sso,
          jwt,
        });
        setRedirectUrl(redirect);
      }
    }
  };

  const handleBack = () => {
    setStep(1);
    setUser(undefined);
    setUserList(undefined);
  };

  const handleSelectUser = (selectedUser) => {
    setStep(4);
    setUser(selectedUser);
    window.createPageLoadEvent('/sign-in#password');
  };

  const handleForgotPassword = async () => {
    if (!user) {
      return;
    }
    const { users, error } = await accountExistenceAsync({
      usernameOrEmail: user.username,
    });
    if (error) {
      window.createErrorEvent(
        {
          controlName: 'forgot-password-link-attempt',
          controlText: f({ id: 'login.forgotPasswordLink' }),
        },
        {
          errorLocation: 'Login-Submit',
          errorType: 'Internal_API',
          errorCode: error.code ?? '',
          errorCause: error?.message.join(',') ?? '',
        }
      );
      toast.errorHtml(error?.message.join(','));
      return;
    }
    if (users) {
      // eslint-disable-next-line prefer-destructuring
      const token = users[0].token;
      Router.push(
        parseCustomParams(`${PASSWORD_RECOVERY_URL}?token=${token}`, params)
      );
    }
  };

  const Headline = (
    <Heading as="h1" align="center">
      <FormattedMessage id="login.signIn" />
    </Heading>
  );

  return (
    <AppCard>
      {step === 1 && !user && (
        <>
          {Headline}
          <UsernameOrEmailForm
            displayCreateAccount={displayCreateAccount}
            onSubmit={handleSubmitUsernameOrEmail}
          />
        </>
      )}

      {step === 2 && authCodeRes && (
        <AuthCodeVerificationWithoutSessionContainer
          headline={Headline}
          subHeadline={
            <Text pt="sm">
              <FormattedMessage
                id="login.verifyAccountMessage.email"
                values={{
                  connectionValue: <strong>({authCodeRes.user.email})</strong>,
                }}
              />
            </Text>
          }
          resendCodeCta={
            <Text>
              <FormattedMessage id="login.resendCode" />
            </Text>
          }
          onResendConnection={EMAIL}
          authCodeRes={authCodeRes}
          onSuccess={handleAuthCodeSuccess}
          onResendCode={setAuthCodeRes}
          trackerResendId="resend-code-account-link"
          trackerVerifyId="verify-account-link"
        />
      )}

      {step === 3 &&
        (userList && userList.length > 0 ? (
          <>
            {Headline}
            <ChooseAccount
              userList={userList}
              onBack={handleBack}
              onSelectUser={handleSelectUser}
            />
          </>
        ) : (
          <>
            {Headline}
            <NoPrimaryAccountFound onBack={handleBack} />
          </>
        ))}

      {step === 4 && user && (
        <>
          {Headline}
          {userList && (
            <>
              <Box textAlign="center" mt={24}>
                <ButtonLink bold={false} onClick={() => setStep(3)}>
                  <FormattedMessage id="login.selectDifferentAccount" />
                </ButtonLink>
              </Box>
              <LastSignedIn>
                <Text>
                  <FormattedMessage id="login.LastSignedIn" />:{' '}
                  {user.lastSignedIn ? user.lastSignedIn : '-'}
                </Text>
                {user.affiliation && (
                  <img
                    src={user.affiliation.logo}
                    alt={`${user.affiliation.name} Logo`}
                  />
                )}
              </LastSignedIn>
            </>
          )}
          <PasswordForm
            password={hiddenPassword}
            onSubmit={handleSubmitPassword}
            OnForgotPassword={handleForgotPassword}
            userStatusLocked={user.is_locked}
          />
        </>
      )}

      {step === 5 && (
        <>
          <Heading as="h1" align="center">
            <FormattedMessage id="login.signingYouIn" />
          </Heading>
          <Box mt="30px">
            <LoadingIcon
              color={useTheme().colors.primary}
              backgroundColor={useTheme().colors.white}
              width="64px"
            />
          </Box>
        </>
      )}

      {auth && redirectUrl && (
        <AutoLoginContainer auth={auth} redirectTo={redirectUrl} />
      )}
    </AppCard>
  );
};
export default LoginPage;
